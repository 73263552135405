import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmaWave from "src/components/nonbmaWave"
import BelowFold from "src/components/btf"
import Disclosure from "@tightrope/disclaimers"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

const btfData = import("./data/btfdata.json");
const wavelpData = import("./data/data.json");
const footer = <RawFooter></RawFooter>


export default function bmTideSpb() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
      #disclaimers-module--disclaimers {
        color: #666;
        width: 600px;
        text-align:center;
        margin: 0 auto;
        line-height: 135%;
        font-size: 12px;
      }

      .wave-module--wave h1 {
        font-size: 24px;
      }

       .footerlinks-module--br {
         display: none!important;
       }

       .wave-module--wave .wave-module--header img {
         padding: 8px 0 8px 8px;

       }

       .wave-module--wave .wave-module--center {
         width: 100%;
       }

       .wave-module--wave ul.wave-module--bullets li {
         padding-bottom: 10px;
         flex: 0 1 45%!important;
       }

       .wave-module--wave ul.wave-module--bullets {
         width: 100%;
    max-width: 850px;
       }

       .wave-module--wave button.wave-module--waveBtn{
       	animation: 1.5s normal 0.5s 1;
        animation-name: slideInFromLeft;
       	animation-fill-mode: forwards;

       }
       @keyframes slideInFromLeft {
       	from {
       		background-position: right bottom;
       	}
       	to {
       		background-position: 0 0;
       		background-color: #096bfb;
       	}
       }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>Cutting Edge AI | AnswerBuddy</title></Helmet>
    <section>
      <NonbmaWave data={wavelpData}><Disclosure></Disclosure>
</NonbmaWave>
<BelowFold data={btfData} footer={footer}><Disclosure></Disclosure>
</BelowFold>
      </section>
    </HomepageLayout>
  )
}
